<template>
  <section
    class="collapsible-section"
    ref="sectionElem"
    v-class-mod:collapsible-section="{ useOverlay, useEnableTroughHeader, isOpen: internalIsOpen }"
  >
    <header
      :tabindex="useEnableTroughHeader ? 0 : -1"
      class="collapsible-section__header"
      @click="headerClick"
      @keydown.enter="headerClick"
    >
      <div class="collapsible-section__header-slot">
        <slot name="header" />
      </div>
      <icon-toggle-button
        :action="() => toggle()"
        :icon="internalIsOpen ? 'expand_less' : 'expand_more'"
        button-size="medium"
        class="collapsible-section__toggle"
      />
    </header>
    <template v-if="useOverlay">
      <app-teleport v-if="internalIsOpen">
        <div
          class="collapsible-section__overlay"
          ref="overlayElem"
          v-lock-focus="true"
          v-stick-to="{
            align: 'right',
            stick: 'left',
            placement: 'bottom',
            stickTo: sectionElem,
            element: overlayElem,
            enableCover: true,
            enableFullWidth: true,
          }"
          v-click-outside="{
            callback: () => toggle(false),
            mustBeLast: true,
          }"
        >
          <slot name="content" />
        </div>
      </app-teleport>
    </template>
    <template v-else>
      <section v-if="hasBeenOpened" v-show="internalIsOpen" class="collapsible-section__content-slot">
        <slot name="content" />
      </section>
    </template>
  </section>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import { AppTeleport } from "@horizon56/bootstrap";
import { vClickOutside } from "@horizon56/directives/click-outside";
import { vLockFocus } from "@horizon56/directives/lock-focus";
import { vStickTo } from "@horizon56/directives/stick-to";

import iconToggleButton from "@/components/buttons/icon-toggle-button.vue";

const props = defineProps<{ isOpen?: boolean; useOverlay?: boolean; useEnableTroughHeader?: boolean }>();
const emits = defineEmits<{ (e: "is-open", a: boolean): void }>();

const sectionElem = ref<HTMLElement>();
const overlayElem = ref<HTMLElement>();

const internalIsOpen = ref(!!props.isOpen);
const hasBeenOpened = ref(internalIsOpen.value);

const headerClick = () => {
  if (props.useEnableTroughHeader) {
    toggle(true);
  }
};

const toggle = (is = !internalIsOpen.value) => {
  internalIsOpen.value = is;
  emits("is-open", internalIsOpen.value);
  if (internalIsOpen.value) {
    hasBeenOpened.value = true;
  }
};

watch(
  () => props.isOpen,
  (is) => {
    if (is !== internalIsOpen.value) {
      internalIsOpen.value = is;
      if (internalIsOpen.value) {
        hasBeenOpened.value = true;
      }
    }
  },
);
</script>

<style lang="scss" scoped>
.collapsible-section {
  display: flex;
  flex-flow: column;
  background: var(--menu-hover);
  border-radius: var(--app-radius-small);
  padding: var(--app-spacing-size-xsmall);
  padding-left: var(--app-spacing-size-small);
  row-gap: var(--app-spacing-size-small);
  &--useEnableTroughHeader:not(&--isOpen):hover {
    cursor: pointer;
    background: var(--menu-active);
  }
  &:has(&__header:focus-visible) {
    @include focus-outline-inner;
  }
  &__header {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    user-select: none;
    outline: none;
  }
  &__toggle {
    margin-left: auto;
  }
  &__header-slot,
  &__content-slot {
    display: flex;
    flex-flow: column;

    &:empty {
      display: none;
    }
  }
  &__header-slot {
    justify-content: center;
    margin-right: var(--app-spacing-size-small);
  }
  &__overlay {
    width: calc(100%);
    position: fixed;
    @include sticky-content;
    border-radius: var(--app-radius-small);
    background: var(--menu-hover);
  }
}
</style>
